.radioButtonInput {
    position: absolute;
    opacity: 0;
    width: 0;
    bottom: 0;
    height: 0;
}

.checkBoxInput {
    position: absolute;
    width: 1;
    bottom: 1;
    height: 1;
}

.radioButtonImageBase {
    text-align: center;
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    color: #858585;
    transition: 0.3s ease-out;
    border-width: thin;
    border-style: solid;
    border-color: #858585;
}

.radioButtonImage {
    cursor: pointer;
    padding: 14px;
    padding-left: 28px;
    border-radius: 10px;
    color: #858585;
    background: #FFF;
    transition: 0.3s ease-out;
    border-width: thin;
    border-style: solid;
    border-color: #858585;
}

.radioButtonSelector {
    cursor: pointer;
    padding: 14px;
    color: #858585;
    background: #FFF;
    transition: 0.3s ease-out;
    border-width: thin;
    border-style: solid;
    border-color: #858585;
}

.radioButtonSelectorLeft {
    border-radius: 10px 0px 0px 10px;
}

.radioButtonSelectorRight {
    border-radius: 0px 10px 10px 0px;
}

.radioButtonImageBase svg, .radioButtonImage svg{
    width: 50px;
    height: 50px;
}

.radioButtonInput:checked + .radioButtonImageBase,
.radioButtonInput:checked + .radioButtonImage,
.radioButtonInput:checked + .radioButtonSelector {
    color: #FFF;
    background: #386098;
}

.radioButtonColor {
    cursor: pointer;
    padding: 48px;
    padding-left: 28px;
    border-radius: 10px;
    color: #858585;
    background: #FFF;
    transition: 0.3s ease-out;
    border-width: thin;
    border-style: solid;
    border-color: #858585;
}

.radioButtonInput:checked + .radioButtonColor {
    filter: opacity(60%);
    border-width: medium;
    border-color: #386098;
}

/* .radioButtonInput:checked + .radioButtonColor::after {
    content: "✔";
} */

.radioButtonInput:disabled + .radioButtonSelector {
    color: #FFF;
    background: #c9c9c9;
    box-shadow: none;
}
